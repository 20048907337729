<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('ISSUE_SYSTEM_CLIENT')}}</div>
            </div>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" v-model="obj"  :option="setData.option" :page.sync="page" @current-change="currentChange">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <!--查询框-->
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="issueCode" :placeholder="$t('Please enter the work order number')" clearable size="small"
                                      style="width:250px !important;marginRight: 5px;"></el-input>
                            <el-select v-model.trim="wtlx" :placeholder="$t('Please enter the question type')" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;">
                                <el-option
                                        v-for="item in typeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model.trim="gdzt" :placeholder="$t('Please enter the work order status')" clearable size="small"
                                       style="width:250px !important;marginRight: 5px;">
                                <el-option
                                        v-for="item in status"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <!--按钮-->
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style=" text-align: right; align-self: flex-end;">
                            <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain @click="addInfo">{{$t('New')}}</el-button>
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain @click="refreshChange">{{$t('Refresh')}}</el-button>
                        </el-col>
                    </el-row>
                </template>
                <template slot-scope="{row,index}" slot="menu">
                    <el-button icon="el-icon-edit" type="text" size="mini" :title="$t('Edit')" @click="editInfo(row,index)" v-if="row.status===0">{{$t('Edit')}}</el-button>
                    <el-button icon="el-icon-view" type="text" size="mini" :title="$t('View')" @click="viewInfo(row,index)" v-if="false">{{$t('View')}}</el-button>
                    <el-button icon="el-icon-s-comment" type="text" size="mini" :title="$t('Comment')" @click="commentInfo(row,index)" v-if="row.status!==2">{{$t('Comment')}}</el-button>
                    <el-button icon="el-icon-delete" type="text" size="mini" :title="$t('Delete')" @click="deleteInfo(row,index)" v-if="row.status===0">{{$t('Delete')}}</el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {delIssue, list} from "@/api/issue/issue";
    export default {
        data() {
            return {
                issueCode: "",
                wtlx: "",
                gdzt:"",
                obj: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false ,
                    pageSize: 10
                },
                //问题类型下拉框
                typeList: [
                    {
                        label: '业务分级咨询',
                        value: "业务分级咨询"
                    },
                    {
                        label: '技术咨询',
                        value: "技术咨询"
                    },
                    {
                        label: '用户数据信息咨询',
                        value: "用户数据信息咨询"
                    },
                ],
                status: [
                    {
                        label: '待处理',
                        value: 0
                    },
                    {
                        label: '处理中',
                        value: 1
                    },
                    {
                        label: '已完成',
                        value: 2
                    }

                ],
                data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        align: 'center',
                        columnBtn: false,//列冻结、隐藏按钮
                        simplePage: false,
                        menuAlign: 'center',
                        addBtn:false,
                        editBtn:false,
                        refreshBtn: false,
                        delBtn:false,
                        column: [
                            {
                                label: '工单编号',
                                prop: 'issueCode',
                            },
                            {
                                label: '问题类型',
                                prop: 'issueType',
                            },
                            {
                                label: '工单状态',
                                prop: 'status',
                                type: "radio",
                                dicData: this.status,
                                mock: {
                                    type: 'status',
                                },
                            },
                            {
                                label: '答复人',
                                prop: 'responseBy'
                            },
                            {
                                label: '备注',
                                prop: 'remark'
                            },
                        ]
                    }
                }
            }
        },
        created() {
            this.getList()
        },
        //watch:用于监听data里面的数据是否被修改，一旦修改就可以执行一些其他的操作
        watch: {
            //监听路由对象是否变化
            $route() {
                this.getList()
            }
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //新增
            addInfo() {
                this.$router.push({ path: '/help/issueAdd', query: { sign: 'add', ID: '' } })
            },
            //修改
            editInfo(row){
                this.$router.push({ path: '/help/issueAdd', query: { sign: 'edit', issueCode: row.issueCode } })
            },
            //查看
            viewInfo(row){
                this.$router.push({ path: '/help/issueAdd', query: { sign: 'view', issueCode: row.issueCode } })
            },
            //删除
            deleteInfo(row){
                this.$confirm(this.$t('IsDel')+":"+row.issueCode, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delIssue(row.issueCode).then(() => {
                        this.getList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                });
            },
            //沟通
            commentInfo(row){
                this.$router.push({ path: '/help/issueChat', query: { sign: 'comment', issueCode: row.issueCode, roleSender: "1" } })
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    issueCode:this.issueCode,
                    issueType:this.wtlx,
                    status:this.gdzt
                };
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.data = [];
                        this.page.total = 0;
                    } else {
                        this.page.total = res.data.data.total
                        this.data = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
            },
        }
    }
</script>
<style scoped>
</style>
